import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Text,
  Textarea,
  // hooks
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Wishlist from './WishlishCard';

import { useGuest } from '@/context/guest';
import useWishes from '@/usecase/use-wishes';
import useFormFields from '@/hooks/useFormFields';
import useInvitation from '@/hooks/useInvitation';

import txtWording from './locales';
import { BG_PRIMARY, BG_PRIMARY_GRADIENT, DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import { INPUT_COMMON_PROPS, FORM_LABEL_PROPS, ERROR_TYPE } from './types';
import BorderFrame from '@/components/Common/BorderFrame';

function WishesSection({ lang }) {
  const { guest, isRegistered } = useGuest();
  const { code, name } = guest;
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { isLoading, isMutating, data, error, onPostWish, onRefetch } = useWishes();
  const isInvitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    wish: '',
  });

  useEffect(() => {
    onResetForm();
  }, [isRegistered]);

  const toast = useToast();

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
      });
    }
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate input data
    if (!formFields.name || !formFields.wish) {
      setErrorType({
        name: !formFields.name && txtWording.requiredField[lang],
        wish: !formFields.wish && txtWording.requiredField[lang],
      });
      return;
    }

    await onPostWish({
      code,
      name: formFields.name,
      wish: formFields.wish,
      onError: () => {
        handleSetAlert(false);
      },
      onSuccess: () => {
        onResetForm();
        handleSetAlert(true);
        setErrorType(ERROR_TYPE);
        onRefetch();
      },
    });
  };

  return (
    <Box
      padding="24px 24px"
      bgColor="bgPrimary"
      backgroundImage={`linear-gradient(180deg, ${BG_PRIMARY}, ${BG_PRIMARY_GRADIENT} 400%)`}
    >
      <Box
        padding="42px 24px"
        bgRepeat="no-repeat"
        width="100%"
        bgSize="100% 100%"
        pos={'relative'}
      >
        <BorderFrame isHeader width="100%" left="0" top="0" h="42px" />
        {/* Title & Description sections */}
        <BorderFrame h="calc(100% - 84px)" left="0" top="42px" width="100%" />
        <Box textAlign="center" color="mainColorText" pos={'relative'}>
          <WithAnimation>
            <Heading
              marginTop="32px"
              fontWeight="normal"
              fontSize="4xl"
              fontFamily="heading"
              dangerouslySetInnerHTML={{ __html: txtWording.title[lang] }}
            />
          </WithAnimation>
          <WithAnimation>
            <Text margin="16px 0" dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
          </WithAnimation>
        </Box>
        {/* Box for FORM */}
        <WithAnimation>
          <Box paddingTop="2">
            <FormControl margin="8px 0" isInvalid={errorType.name}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={createChangeHandler('name')}
                value={formFields.name}
              />
              <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
            </FormControl>
            <FormControl margin="16px 0" isInvalid={errorType.wish}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
              <Textarea
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={createChangeHandler('wish')}
                value={formFields.wish}
              />
              <FormErrorMessage marginTop="4px">{errorType.wish}</FormErrorMessage>
            </FormControl>
            <Flex justifyItems="end">
              <Spacer />
              <Box>
                <Button
                  {...DEFAULT_BUTTON_PROPS}
                  isLoading={isMutating}
                  size="sm"
                  padding="8px 24px"
                  bgColor="bgSecondary"
                  fontWeight="light"
                  color="btnMainColor"
                  borderRadius="2px"
                  // textTransform="uppercase"
                  onClick={handleSubmit}
                >
                  {txtWording.send[lang]}
                </Button>
              </Box>
            </Flex>
          </Box>
        </WithAnimation>
        {/* Wishlist Card */}
        {!error && (
          <WithAnimation>
            <Wishlist wishlistData={data} loading={isLoading} />
          </WithAnimation>
        )}
        <BorderFrame height="42px" left="0" w="100%" isFooter />
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
