import P1 from '@/images/1.jpg';
import P2 from '@/images/2.jpg';
import P3 from '@/images/3.jpg';
import P4 from '@/images/4.jpg';
import P5 from '@/images/5.jpg';
import P6 from '@/images/6.jpg';
import P7 from '@/images/7.jpg';
import P8 from '@/images/8.jpg';
import P9 from '@/images/9.jpg';
import P10 from '@/images/10.jpg';
import P11 from '@/images/11.jpg';
import P12 from '@/images/12.jpg';

export const photos = [
  {
    original: P1,
    // thumbnail: P1,
  },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  {
    original: P5,
    // thumbnail: P5,
  },
  {
    original: P6,
    // thumbnail: P6,
  },
  {
    original: P7,
    // thumbnail: P7,
  },
  {
    original: P8,
    // thumbnail: P8,
  },
  {
    original: P9,
    // thumbnail: P8,
  },
  {
    original: P10,
    // thumbnail: P8,
  },
  {
    original: P11,
    // thumbnail: P8,
  },
  {
    original: P12,
    // thumbnail: P8,
  },
];
