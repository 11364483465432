import get from '@invitato/helpers/dist/getLocale';

export default {
  nameayat: get('[Matius 19:6]', '[Philippians 1]'),
  ayat: get("Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”", 
  "“I hold you in my heart, for we have shared together God’s blessings”"),

  nameayatquran: get('[QS. Ar-Rum: 21]', '[QS. Ar-Rum: 21]'),
  ayatquran: get("Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.",
   "And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect."),
};