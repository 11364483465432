import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import DetailCouple from './DetailCard';
import useRsvp from '@/usecase/use-rsvp';
import useCobaLink from '@/hooks/useCobaLink';

import {
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import { BG_PRIMARY, BG_PRIMARY_GRADIENT } from '@/constants/colors';

const BOY_PROPS = {
  fullName: BOY_NAME,
  shortName: BOY_NAME_SHORT,
  parentName: BOY_PARENT_NAME,
  instagramId: IG_BOY,
  imgUrl: IMG_MAN,
};

const GIRL_PROPS = {
  fullName: GIRL_NAME,
  shortName: GIRL_NAME_SHORT,
  parentName: GIRL_PARENT_NAME,
  instagramId: IG_GIRL,
  imgUrl: IMG_GIRL,
};

/**
 * Render couple info
 * @param {*} props
 * @returns
 */
function CoupleInfo({ ...rest }) {
  const FIRST_ITEM = IS_BOY_FIRST ? BOY_PROPS : GIRL_PROPS;
  const SECOND_ITEM = IS_BOY_FIRST ? GIRL_PROPS : BOY_PROPS;

  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  return (
    <Box
      bgColor="bgPrimary"
      backgroundImage={`linear-gradient(180deg, ${BG_PRIMARY}, ${BG_PRIMARY_GRADIENT} 400%)`}
      paddingBottom="62px"
      {...rest}
    >
      <DetailCouple {...FIRST_ITEM} />
      <Box height="52px" />
      <DetailCouple {...SECOND_ITEM} />
    </Box>
  );
}

export default React.memo(CoupleInfo);
