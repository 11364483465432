import get from '@invitato/helpers/dist/getLocale';

export default {
  akadGeneral: get('TEA CEREMONY BRIDE SIDE', 'TEA CEREMONY BRIDE SIDE'),
  akadMuslim: get('AKAD NIKAH','AKAD'),
  
  descAkadGeneral: get('BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, TEA CEREMONY BRIDE SIDE AKAN DISELENGGARAKAN TERBATAS PADA:', 
  'THE TEA CEREMONYWILL BE HELD ON:'),
  descAkadMuslim: get('BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, AKAD NIKAH AKAN DISELENGGARAKAN TERBATAS PADA:',
  'DUE TO RESTRICTIONS ON GATHERING SIZES, THE AKAD WILL BE HELD WITH A LIMITED CAPACITY ON:'),
  
  descAkadInvitationMuslim: get('BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, AKAD NIKAH AKAN DISELENGGARAKAN TERBATAS PADA:',
  'WE WILL TIE THE KNOT! PLEASE WITNESS OUR WEDDING VOWS ON:'),
  descAkadInvitationGeneral: get('BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, TEA CEREMONY BRIDE SIDE AKAN DISELENGGARAKAN TERBATAS PADA:',
  'WE WILL TIE THE KNOT! PLEASE WITNESS OUR WEDDING VOWS ON:'),  

  reception: get('RESEPSI', 'WEDDING RECEPTION'),
  descReception: get('KAMI BERMAKSUD MENGUNDANG BAPAK/IBU/SAUDARA/I DALAM ACARA RESEPSI PERNIKAHAN KAMI YANG AKAN DISELENGGARAKAN PADA:',"LET'S CELEBRATE AND ENJOY OUR FIRST DAY AS A HUSBAND AND WIFE AT A WEDDING RECEPTION THAT WILL BE HELD ON:"),

  location: get('Lihat Lokasi', 'View Location'),
};