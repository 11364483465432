import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Image,
  Text,
  Divider,
  DrawerFooter,
  // hooks
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';

import useInvitation from '@/hooks/useInvitation';
import useIsDesktopView from '@/hooks/useIsDesktopView';
import useLang from '@/hooks/useLang';
import ImgCoverLogo from '@/assets/icons/cover-logo.png';

import { THE_BRIDE } from '@/constants';
import { BG_NAVIGATION, BG_NAVIGATION_ICON_COLOR, DEFAULT_BUTTON_PROPS } from '@/constants/colors';

import useMenudata from './useMenuData';
import txt from './locales';

function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isDesktopView } = useIsDesktopView();
  const menuData = useMenudata();
  const toast = useToast();
  const lang = useLang();

  const btnRef = React.useRef();
  const isInvitation = useInvitation();

  const filteredMenuData = useMemo(
    () =>
      menuData.filter((i) => {
        if (isInvitation) return true;
        if (!i.invitationOnly) return true;
        return false;
      }),
    [],
  );

  const onClickMenu = (ELEMENT_CONTENT_ID, offset) => {
    try {
      onClose();
      // const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
      scroller.scrollTo(ELEMENT_CONTENT_ID, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
      });
    } catch {
      toast({
        title: 'Oops!',
        description: txt.menuNotFound[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        {...DEFAULT_BUTTON_PROPS}
        bgColor={BG_NAVIGATION}
        ref={btnRef}
        borderRadius="50%"
        position="fixed"
        zIndex="999"
        bottom="12px"
        left="12px"
        size="sm"
        boxShadow="md"
        onClick={isOpen ? onClose : onOpen}
        icon={
          isOpen ? (
            <MdClose color={BG_NAVIGATION_ICON_COLOR} />
          ) : (
            <MdMenu color={BG_NAVIGATION_ICON_COLOR} />
          )
        }
      />
      <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent maxWidth={isDesktopView ? '500px' : '85%'} bgColor="bgPrimary" padding="24px 16px">
          <DrawerBody textAlign="right">
            <Image src={ImgCoverLogo} width="200px" position="absolute" right="32px" />
            <Box marginTop="100px">
              {filteredMenuData.map((i) => {
                return (
                  <div key={i.value}>
                    <Text
                      marginTop="16px"
                      color="mainColorText"
                      letterSpacing="3px"
                      fontSize="lg"
                      textTransform="uppercase"
                      cursor="pointer"
                      _hover={{ color: 'black'}}
                      onClick={() => onClickMenu(i.value, i.offset)}
                    >
                      {i.name}
                    </Text>
                    <Divider width="100%" marginTop="8px" />
                  </div>
                );
              })}
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Box textAlign="right" fontSize="xs">
              <Text color="mainColorText">Created with Love by Invitato</Text>
              <Text color="mainColorText">{`${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navigation;
